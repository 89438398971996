import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
// import Home from "./pages/Home";
// import Services from "./pages/Services";
// import About from "./pages/About";
// import Contact from "./pages/Contact";
// import Blog from "./pages/Blog";
// import Faq from "./pages/Faq";
import SpinningLogo from "./components/SpinningLogo";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";
import { HelmetProvider } from "react-helmet-async";

const Home = lazy(() => import("./pages/Home"));
const Services = lazy(() => import("./pages/Services"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Blog = lazy(() => import("./pages/Blog"));
const Faq = lazy(() => import("./pages/Faq"));

function App() {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <div className="app-wrap">
            <Navbar />
            <Suspense fallback={<SpinningLogo />}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/faqs" element={<Faq />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
              <Footer />
            </Suspense>
            <a
              href="https://wa.me/263777651158"
              class="whatsapp_float"
              target="_blank"
              rel="noopener noreferrer"
              title="Chat with us on Whatsapp"
            >
              <i class="fa fa-whatsapp whatsapp-icon"></i>
            </a>
          </div>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>
  );
}

export default App;
