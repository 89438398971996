import React, { useState, useEffect } from "react";
import axios from "axios";

const FormSubcribe = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  // Check local storage on component mount
  useEffect(() => {
    const subscriptionStatus = localStorage.getItem("subscription_status");
    if (subscriptionStatus === "subscribed") {
      setSubmitted(true);
    }
  }, []);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Make a POST request to Formspree endpoint
      const response = await axios.post("https://formspree.io/f/mnqeapzp", {
        fullName,
        email,
      });

      // Handle the response (you can customize this based on your needs)
      setSubmitted(true);
      console.log("Form submitted successfully!", response);
      localStorage.setItem("subscription_status", "subscribed");
    } catch (error) {
      // Handle errors (you can customize this based on your needs)
      // console.error("Error submitting the form:", error);
    }
  };

  return (
    <>
      {submitted ? (
        <p id="thanks-subscribed">Subscribed!</p>
      ) : (
        <form className="subscribe-form" onSubmit={handleSubmit}>
          <label>
            <input
              id="fullName"
              name="fullName"
              type="text"
              placeholder="Full Name"
              autoComplete="name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </label>
          <label>
            <input
              type="email"
              placeholder="Email"
              id="email"
              name="email"
              value={email}
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </label>
          <input type="submit" value="Subscribe" id="subscribe-btn" />
        </form>
      )}
    </>
  );
};

export default FormSubcribe;
