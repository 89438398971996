import React, { useState, useEffect } from 'react';
import { Navigate, Link } from 'react-router-dom';

const NotFound = () => {
  const [redirectCount, setRedirectCount] = useState(5);

  useEffect(() => {
    const countdown = setInterval(() => {
      setRedirectCount((prevCount) => prevCount - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(countdown);
  }, []);

  // Redirect to the home page after the countdown
  if (redirectCount === 0) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <h1>Oooops... 404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>Redirecting you to our home page in {redirectCount} seconds...</p>
      <p>If you are not redirected, you can click <Link to="/">here</Link>.</p>
    </div>
  );
};

export default NotFound;