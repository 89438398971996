import React from 'react';
import FormSubcribe from './FormSubcribe';
import { FaFacebookSquare, FaLinkedin } from 'react-icons/fa';

const Footer = () => {
  return ( 
  <div className="footer-wrap" id="footer">
    <div className="footer-top">
      <div className="paragraph">
        <p id='be-first'>
        Be the first to know! Please send me updates about upcoming Prime Equilibrium events, and occasional resources for living a full and meaningful life.
        </p>
      </div>
      <div className="subscribe-form-wrap">
        <FormSubcribe />
      </div>
    </div>
    <div className="footer-middle">
      <div className="services">
        <p className="services-p">
        Services for:
          <ul className="services-ul">
            <li>Statutory businesses</li>
            <li>Charities</li>
            <li>Private clients</li>
          </ul>
        </p>
      </div>
      <div className="visit-us">
        <p className="visit-us-p">Visit Our Pages:</p>
        <ul className="visit-us-ul">
        <li><a href="https://www.facebook.com/primeequilibrium/" target="_blank" rel="noreferrer"><FaFacebookSquare style={{color: "#4267B2"}} /></a></li>
          <li><a href="https://www.linkedin.com/in/mazvita-mwayera-849bb6108/" target="_blank" rel="noreferrer"><FaLinkedin style={{color: "#0077b5"}} /></a></li>
        </ul>
        <p className="visit-us-email">info@primeequilibrium.com</p>
      </div>
    </div>
    <div className="footer-bottom">
      <p className="copyright">© 2023 Prime Equilibrium | <a href="https://www.linkedin.com/in/timothy-tinashe-murambinda/" target="_blank" rel="noreferrer">Developer</a></p>
    </div>
  </div> );
}
 
export default Footer;