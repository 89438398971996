import React, { useEffect, useState } from "react";
import logo from "../assets/Logobg.png";
// import logotext from "../assets/PEtext-removebg3.png";
import { Link, useLocation } from "react-router-dom";
import { FaFacebookSquare, FaLinkedin, FaHamburger, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar-top-wrap">
        <ul className="social-icons">
          <li>
            <a
              href="https://www.facebook.com/primeequilibrium"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare style={{ color: "#fff" }} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/mazvita-mwayera-849bb6108/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin style={{ color: "#fff" }} />
            </a>
          </li>
        </ul>
      </div>
      <div className="navbar-wrap">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="logo" className="img-logo" />
          </Link>
          { isMobileMenuOpen && (
          <div className="hamburger2" onClick={toggleMobileMenu}>
            <FaTimes style={{ color: "#e32649", width: "30px", height: "30px"  }} />
          </div>
          )}
          {/* <img src={logotext} alt="logotext" className="img-logo-text" /> */}
        </div>
        <ul className="navlinks">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          {/* <li>
            <Link to="/about-us">About</Link>
          </li> */}
          <li>
            <Link to="/contact-us">Contact</Link>
          </li>
          <li className="faqs">
            <Link to="/faqs">FAQs</Link>
          </li>
        </ul>
        <div className="hamburger" onClick={toggleMobileMenu}>
          <FaHamburger style={{ color: "#e32649", width: "30px", height: "30px" }} />
        </div>

        {isMobileMenuOpen && (
        <>

        <ul className="navlinks-mobile">

          <li onClick={toggleMobileMenu}>
            <Link to="/">Home</Link>
          </li>
          <li onClick={toggleMobileMenu}>
            <Link to="/services">Services</Link>
          </li>
          <li onClick={toggleMobileMenu}>
            <Link to="/blog">Blog</Link>
          </li>
          {/* <li onClick={toggleMobileMenu}>
            <Link to="/about-us">About</Link>
          </li> */}
          <li onClick={toggleMobileMenu}>
            <Link to="/contact-us">Contact</Link>
          </li>
          <li className="faqs" onClick={toggleMobileMenu}>
            <Link to="/faqs">FAQs</Link>
          </li>
          <img src={logo} alt="logo" className="img-logo" />
        </ul>
        </>
        )}
      </div>
    </div>
  );
};

export default Navbar;
