import React from 'react';
import spinLogo from '../assets/Logobg.png';

const SpinningLogo = () => {
  return (
    <div className="spinning-logo-container">
      <img
        src={spinLogo} 
        alt="Logo"
        className="spinning-logo"
      />
    </div>
  );
};

export default SpinningLogo;